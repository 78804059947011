import * as React from 'react';
import type { PiletApi } from '@sharefiledev/sharefile-appshell';
import InitialManifest from './uiManifest.json';

interface Props {
	piral: PiletApi;
}

const BlockDevelopment = ({ piral: { Extension } }: Props) => {
	return (
		<Extension
			name="urn:block:view-engine:blockdevelopment"
			params={{ pluginKey: 'client-mgt-pilet', initialManifest: InitialManifest }}
		/>
	);
};

export default BlockDevelopment;
