import { Users } from '@sharefiledev/icons';
import { type PiletApi } from '@sharefiledev/sharefile-appshell';
import { addBlocks } from './blocks';
import { addBlockDevelopment } from './development';
import { AsyncClientManagementConfiguration } from './extensions/ClientManagementConfiguration';
import { ClientManagementHomePage } from './extensions/ClientManagementHomePage';
import { ClientViewPage } from './extensions/ClientViewPage';
import { WorkflowsClientContactSelectorExtension } from './extensions/WorkflowsClientContactSelector';
import { addOverlays } from './overlays';

const smokeTestCustomers = [
	'pilet-tests.sharefiletest.com',
	'pilet-tests.sharefilestaging.com',
	'pilet-tests.sharefile.com',
];

export function setup(piral: PiletApi) {
	const { user, account } = piral.sf.piletUserContext.get();
	const clientMgtEnabled = account.Preferences?.EnableClientManagement ?? false;

	if (!clientMgtEnabled) {
		return;
	}

	addBlocks(piral);
	addOverlays(piral);

	const isUserClientType = user.Roles?.includes('Client');
	piral.sf.registerLeftNavComponent({
		href: '/clients',
		title: () => 'Clients',
		icon: Users,
		weight: 50,
		isAvailable: () => !isUserClientType,
	});

	piral.registerPage('/clients/settings', AsyncClientManagementConfiguration);
	piral.registerPage('/clients/view/:clientRID', ClientViewPage);
	piral.registerPage('/clients', ClientManagementHomePage);

	piral.registerExtension(
		'urn:sfextslot:client-mgt-pilet:domino:client-contact:type',
		WorkflowsClientContactSelectorExtension
	);

	if (
		process.env.NODE_ENV === 'development' ||
		smokeTestCustomers.includes(location.hostname)
	) {
		addBlockDevelopment(piral);

		if (
			process.env.NODE_ENV === 'development' &&
			typeof piral.meta.config.apiOrigin === 'string'
		) {
			/**
			 * Manual require ensures tree-shaking
			 * does not include these modules in the entry index.js
			 */
			const { Configuration } = require('./generated/runtime');
			const { configureHttp } = require('./httpConfiguration');
			configureHttp((config: any) => {
				config.config = new Configuration({
					basePath: `${piral.meta.config.apiOrigin}/io/clientmgt/v1`,
				});
			});
		}
	}
}
