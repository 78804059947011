import React from 'react';
import { PageComponentProps } from '@sharefiledev/sharefile-appshell';
import { ViewConverter, ViewLoader } from '@sharefiledev/solution-view-engine';
import { useParams } from 'react-router-dom';

export const ClientViewPage: React.FC<PageComponentProps> = ({ piral }) => {
	const accountID = piral.sf.piletUserContext.get().account.Id;
	const containerRID = `sharefile:account-${accountID}`;
	const { clientRID: clientRIDFromURI } = useParams<{ clientRID: string }>();
	const clientRID = decodeURIComponent(clientRIDFromURI);
	const defaultClientViewPage = ViewConverter.convertView({
		layout: {
			block: {
				pluginId: 'urn:client-mgt',
				blockName: 'urn:block:client-mgt-pilet:view-client',
				blockParameters: {
					clientRID: '{{clientRID}}',
					padding: '32px',
				},
				zones: [
					{
						zoneType: 'urn:zone:client-mgt-pilet:view-client:overview:widget',
						zoneParameters: {
							xs: {
								span: 24,
							},
							sm: {
								span: 24,
							},
							md: {
								span: 12,
							},
							lg: {
								span: 12,
							},
							xl: {
								span: 12,
							},
							xxl: {
								span: 12,
							},
						},
						blocks: [
							{
								pluginId: 'urn:client-mgt-pilet',
								blockName: 'urn:block:client-mgt-pilet:client-contacts-widget',
								blockParameters: {
									clientRID: '{{clientRID}}',
								},
							},
						],
					},
					{
						zoneType: 'urn:zone:client-mgt-pilet:view-client:create:option',
						zoneParameters: {
							testId: 'contact-add-option',
							label: {
								en: 'Add contact',
								de: 'Add contact',
								es: 'Add contact',
								fr: 'Add contact',
								it: 'Add contact',
								ja: 'Add contact',
								nl: 'Add contact',
								'pt-BR': 'Add contact',
							},
							handleWith: 'drawer',
							extensionHandlerName: 'urn:sfdrawer:client-mgt-pilet:client-contact:intake',
							titleForHandler: {
								en: 'Add contact',
								de: 'Add contact',
								es: 'Add contact',
								fr: 'Add contact',
								it: 'Add contact',
								ja: 'Add contact',
								nl: 'Add contact',
								'pt-BR': 'Add contact',
							},
						},
					},
					{
						zoneType: 'urn:zone:client-mgt-pilet:view-client:create:option',
						zoneParameters: {
							testId: 'service-contact-add-option',
							label: {
								en: 'Add team member',
								de: 'Add team member',
								es: 'Add team member',
								fr: 'Add team member',
								it: 'Add team member',
								ja: 'Add team member',
								nl: 'Add team member',
								'pt-BR': 'Add team member',
							},
							handleWith: 'drawer',
							extensionHandlerName: 'urn:sfdrawer:client-mgt-pilet:service-contacts:add',
							titleForHandler: {
								en: 'Add team member',
								de: 'Add team member',
								es: 'Add team member',
								fr: 'Add team member',
								it: 'Add team member',
								ja: 'Add team member',
								nl: 'Add team member',
								'pt-BR': 'Add team member',
							},
						},
					},
					{
						zoneType: 'urn:zone:client-mgt-pilet:view-client:create:option',
						zoneParameters: {
							testId: 'create-project-option',
							label: {
								en: 'Create project',
								de: 'Create project',
								es: 'Create project',
								fr: 'Create project',
								it: 'Create project',
								ja: 'Create project',
								nl: 'Create project',
								'pt-BR': 'Create project',
							},
							handleWith: 'more',
							options: [
								{
									testId: 'create-blank-project-option',
									label: {
										en: 'Blank project',
										de: 'Blank project',
										es: 'Blank project',
										fr: 'Blank project',
										it: 'Blank project',
										ja: 'Blank project',
										nl: 'Blank project',
										'pt-BR': 'Blank project',
									},
									handleWith: 'drawer',
									extensionHandlerName: 'urn:drawer:projects-pilet:create-project',
									onCompleteInteraction: {
										eventName: 'urn:client-mgt-pilet:associate-resources-with-client',
										eventPayload: {
											rids: ['{{data}}'],
										},
									},
								},
								{
									testId: 'create-project-from-template-option',
									label: {
										en: 'From template',
										de: 'From template',
										es: 'From template',
										fr: 'From template',
										it: 'From template',
										ja: 'From template',
										nl: 'From template',
										'pt-BR': 'From template',
									},
									handleWith: 'modal',
									extensionHandlerName: 'urn:sfmodal:templates-pilet:use-template',
									overlayOptions: {
										resourceType: 'sharefile:project',
									},
									onCompleteInteraction: {
										eventName: 'urn:client-mgt-pilet:associate-resources-with-client',
										eventPayload: {
											rids: ['{{data}}'],
										},
									},
								},
							],
						},
					},
				],
			},
		},
		view: {
			name: 'View Client',
		},
	});

	return (
		<ViewLoader
			containerRID={containerRID}
			piletApi={piral}
			fallbackView={defaultClientViewPage}
			metadata={{ source: 'client-mgt-pilet', purpose: 'view-client' }}
			viewContext={{ clientRID, containerRID }}
		/>
	);
};
