import React from 'react';
import { PageComponentProps } from '@sharefiledev/sharefile-appshell';
import { ViewConverter, ViewLoader } from '@sharefiledev/solution-view-engine';

const AsyncLegacyTokenInterceptor = React.lazy(
	() => import('../components/LegacyTokenInterceptor')
);

const defaultClientManagementHomePage = ViewConverter.convertView({
	layout: {
		block: {
			pluginId: 'urn:client-mgt',
			blockName: 'urn:block:client-mgt-pilet:list-clients',
			blockParameters: {
				padding: '0 32px 32px',
			},
		},
	},
	view: { name: 'Clients Home' },
});

export const ClientManagementHomePage: React.FC<PageComponentProps> = ({
	piral,
	...componentProps
}) => {
	const { account } = piral.sf.piletUserContext.get();
	const accountId = account.Id ?? '';
	const accountRID = React.useMemo(() => `sharefile:account-${accountId}`, [accountId]);
	return (
		<React.Suspense fallback={<div data-testid="loading-legacy-token-interceptor" />}>
			<AsyncLegacyTokenInterceptor piral={piral} {...componentProps}>
				<ViewLoader
					containerRID={accountRID}
					piletApi={piral}
					fallbackView={defaultClientManagementHomePage}
					metadata={{ source: 'client-mgt-pilet', purpose: 'list-clients' }}
				/>
			</AsyncLegacyTokenInterceptor>
		</React.Suspense>
	);
};
