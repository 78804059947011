import React from 'react';
import {
	ClientAssociateResourcesBlockParams,
	ClientContactIntakeBlockParams,
	ClientContactsWidgetBlockParams,
	ClientIntakeBlockParams,
	ClientUpdateBlockParams,
	ContactsTableBlockParams,
	ListClientsBlockParams,
	ServiceContactsWidgetBlockParams,
	SuggestedContactsWidgetBlockParams,
	ViewClientBlockParams,
} from './extensions';

const LazyViewClient = React.lazy(() => import('./ViewClient'));
export const AsyncViewClient: React.FC<ViewClientBlockParams> = param => {
	return (
		<React.Suspense fallback={<div data-testid="loading-view-client" />}>
			<LazyViewClient {...param} />
		</React.Suspense>
	);
};

const LazyListClients = React.lazy(() => import('./ListClients'));
export const AsyncListClients: React.FC<ListClientsBlockParams> = param => {
	return (
		<React.Suspense fallback={<div data-testid="loading-list-clients" />}>
			<LazyListClients {...param} />
		</React.Suspense>
	);
};
const LazyClientIntake = React.lazy(() => import('./ClientIntake'));
export const AsyncClientIntake: React.FC<ClientIntakeBlockParams> = param => {
	return (
		<React.Suspense fallback={<div data-testid="loading-client-intake" />}>
			<LazyClientIntake {...param} />
		</React.Suspense>
	);
};

const LazyClientContactIntake = React.lazy(() => import('./ClientContactIntake'));
export const AsyncClientContactIntake: React.FC<
	ClientContactIntakeBlockParams
> = param => {
	return (
		<React.Suspense fallback={<div data-testid="loading-client-intake" />}>
			<LazyClientContactIntake {...param} />
		</React.Suspense>
	);
};

const LazyClientUpdate = React.lazy(() => import('./ClientUpdate'));
export const AsyncClientUpdate: React.FC<ClientUpdateBlockParams> = param => {
	return (
		<React.Suspense fallback={<div data-testid="loading-client-update" />}>
			<LazyClientUpdate {...param} />
		</React.Suspense>
	);
};

const LazyContactsTable = React.lazy(() => import('./ContactsTable'));
export const AsyncContactsTable: React.FC<ContactsTableBlockParams> = param => {
	return (
		<React.Suspense fallback={<div data-testid="loading-contacts-table" />}>
			<LazyContactsTable {...param} />
		</React.Suspense>
	);
};

const LazyClientContactsWidget = React.lazy(() => import('./ClientContactsWidget'));
export const AsyncClientContactsWidget: React.FC<
	ClientContactsWidgetBlockParams
> = param => {
	return (
		<React.Suspense fallback={<div data-testid="loading-client-contacts-widget" />}>
			<LazyClientContactsWidget {...param} />
		</React.Suspense>
	);
};

const LazyServiceContactsWidget = React.lazy(() => import('./ServiceContactsWidget'));
export const AsyncServiceContactsWidget: React.FC<
	ServiceContactsWidgetBlockParams
> = param => {
	return (
		<React.Suspense fallback={<div data-testid="loading-service-contacts-widget" />}>
			<LazyServiceContactsWidget {...param} />
		</React.Suspense>
	);
};

const LazyClientAssociateResources = React.lazy(
	() => import('./ClientAssociateResources')
);
export const AsyncClientAssociateResources: React.FC<
	ClientAssociateResourcesBlockParams
> = param => {
	return (
		<React.Suspense fallback={<div data-testid="loading-client-associate-resources" />}>
			<LazyClientAssociateResources {...param} />
		</React.Suspense>
	);
};

const LazyClientAssociateResourcesIntake = React.lazy(
	() => import('./ClientAssociateResourcesIntake')
);
export const AsyncClientAssociateResourcesIntake: React.FC<
	ClientAssociateResourcesBlockParams
> = param => {
	return (
		<React.Suspense
			fallback={<div data-testid="loading-client-associate-resources-intake" />}
		>
			<LazyClientAssociateResourcesIntake {...param} />
		</React.Suspense>
	);
};

const LazySuggestedContactsWidget = React.lazy(() => import('./SuggestedContactsWidget'));
export const AsyncSuggestedContactsWidget: React.FC<
	SuggestedContactsWidgetBlockParams
> = param => {
	return (
		<React.Suspense fallback={<div data-testid="loading-suggested-contacts-widget" />}>
			<LazySuggestedContactsWidget {...param} />
		</React.Suspense>
	);
};
