import React from 'react';
import { OverlayDrawerComponentProps } from '@sharefiledev/sharefile-appshell';
import { PreConfiguredBlock } from '@sharefiledev/solution-view-engine';
import { ClientEntity } from '../generated';

interface AssociateToClientDrawerProps
	extends OverlayDrawerComponentProps<'urn:sfdrawer:client-mgt-pilet:client-associate-resources:intake'> {}

export const AssociateToClientDrawer: React.FC<AssociateToClientDrawerProps> = ({
	piral,
	completeInteraction,
	cancelInteraction,
	options,
}) => {
	const subjectRID = options?.subjectRID;
	const userRIDs = options?.userRIDs;
	const associatedClient = options?.associatedClient;

	React.useEffect(() => {
		const completeCallback = ({ client }: { client: ClientEntity }) => {
			completeInteraction({ newClient: client });
		};
		const cancelCallback = () => {
			cancelInteraction();
		};
		piral.on('urn:sfdrawer:client-mgt-pilet:client-associate-complete', completeCallback);
		piral.on('urn:sfdrawer:client-mgt-pilet:client-associate-cancel', cancelCallback);
		return () => {
			piral.off(
				'urn:sfdrawer:client-mgt-pilet:client-associate-complete',
				completeCallback
			);
			piral.off('urn:sfdrawer:client-mgt-pilet:client-associate-cancel', cancelCallback);
		};
	}, [completeInteraction, cancelInteraction, piral]);

	return (
		<div data-testid="associate-to-client-drawer" style={{ margin: '-20px -24px -32px' }}>
			<PreConfiguredBlock
				piletApi={piral}
				configuration={{
					pluginId: 'urn:client-mgt-pilet',
					blockName: 'urn:block:client-mgt-pilet:client-associate-resources-intake',
					blockParameters: {
						subjectRID,
						userRIDs,
						associatedClient,
						contentPadding: '20px',
					},
				}}
			/>
		</div>
	);
};
